import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import TemplateUser from '~/templates/user';
import requireUser from '~/utils/requireUser';

const PageUserCalendrier: FC<PageProps> = ({ params: { userId } }) => (
  <TemplateUser>
    <div>Calendrier {userId}</div>
  </TemplateUser>
);

export default requireUser(PageUserCalendrier);
